.Modal_Config {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.h2_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 1000;
  font-size: 24px;
  line-height: 39px;
  color: #1e222b;
  margin-bottom: 1rem;
}

.Imagem_Participante {
  width: 366.39px;
  height: 172px;
  margin-left: 1rem;
  background: #000000;
  border-radius: 4.07101px;
}

.Linha_Um {
  display: flex;
  justify-content: center;
  color: #009ef7;
  gap:1.6rem;
  /* margin: 0 10px 0 10px; */
  margin-left: .1rem;
  margin-top: 1rem;
}

.borda_button {
  display: flex;
  /* box-sizing: border-box; */
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 160px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #009ef7;
  border-radius: 4.49px;
  transition: ease-in-out 0.5s ;
}

.borda_button:hover{
  transform: scale(1.05);
}

.h3_Modal {
  width: 107.57px;
  height: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #009ef7;
  margin-bottom: 10px;
  /* display: flex;
  justify-content: center; */
}
.btn_Cancel_Modal_Config {
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1e222b;
  margin-left: 125px;
  margin-top: 1rem;
  cursor: pointer;
  transition: ease-in-out 0.5s ;
}

.btn_Cancel_Modal_Config:hover{
  transform: scale(1.08); 
}
