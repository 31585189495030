@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,100;1,200;1,300&family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&family=Roboto:ital,wght@0,300;1,300&display=swap");

* {
  box-sizing: border-box;
  scroll-behavior: smooth;

  
}

body {
 /* padding-top: 10rem ; */
  margin: 0;
  background-color: #1e222b;
  --type-first: Poppins;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,li {
  list-style: none;
  padding: 0px;
  margin:0px;
}

/* img {
  display: block; 
   max-width: 100%;
} */

button, input {
  display:block;
  font-family: var(--type-first);
 
}

a {
  text-decoration: none;
}

.container {
  max-width: 1280px;
  padding: 1rem 2rem;
  margin: 0 auto;
  position: absolute;
}

