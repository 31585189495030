.modal {
  display: block;
  justify-content: center;
  align-items: center;
  width: 404px;
  height: auto;
  padding-bottom: 1.5rem;
  background: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6); */
  /* min-width: 300px;
  min-height: 200px;
  max-width: 700px;
  max-height: 450px; */
  /* position: absolute; */
}

.modal_close {
  font-size: 16px;
  width: 22px;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 2px;
  margin-left: 23rem;
  margin-bottom: 0px;
  cursor: pointer;
  color: #1e222b;
  z-index: 1;
}

.modal_body {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Error_Message {
  display: flex;
  flex-direction: column;
}

.Error_h2 {
  justify-content: center;
  width: 328px;
  height: 45px;
  left: 478px;
  top: 333px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1e222b;
}

.Error_Data {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 328px;
  height: 53px;
  left: 498px;
  top: 412px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  color: red;
}

.Outline_Icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .agadoisuser {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 45px;
  left: 477px;
  top: 218px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: #1e222b;
  margin-bottom: 2px;
} */

@media (max-width: 400px) {
  .modal {
    padding: 15px 70px 15px 15px;
  }
}
