.icondata {
  height: 48px;
  width: 43px;
  padding: 4px;
  position: absolute;
  box-sizing:border-box;
  top:49%;
  left:266.5px;
  transform: translateY(-65%);  
  display: flex;
  justify-content: center;
  align-items: center;
  background: #009ef7;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;

}

.icone_agendar {
  position: relative;
}

.input_Modal{
  box-sizing:border-box;
  padding-left: 1.5rem;
  
}



