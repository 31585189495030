.containerhome {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  margin: 0 auto;
  margin: 8rem 0 0 20rem;
  max-width: 900px;
  min-height: 100%;
  min-height: 80vh;
  width: 100%;
}
/* background-color: red; */

.titulo1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 24px;
  width: 900px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.boxtransmissao {
  display: flex;
  align-items: flex-end;
  width: 900px;
  gap: 2rem;
  margin: 2rem;
}
.transmissao {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 195px;
  height: 133px;
  left: 292px;
  top: 198px;
  /* background: #009ef7; */
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #ffffff;
  gap: 1rem;
}

h3 {
  font-size: 12px;
}

.icone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  gap: 0.5rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15em;
}

.transmissao2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 195px;
  height: 133px;
  left: 292px;
  top: 198px;
  background: #424242;
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #ffffff;
  gap: 1rem;
}

/* .transmissao3{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 195px;
  height: 133px;
  left: 292px;
  top: 198px;
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #ffffff;
  gap: 1rem;
} */

.titulo2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 24px;
  width: 900px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.boxagendadas {
  display: flex;
  align-items: flex-start;
  width: 900px;
  gap: 2rem;
  margin: 2rem;
}

.agendadas {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 195px;
  height: 133px;
  left: 292px;
  top: 198px;
  padding: 1rem 2rem;
  background: #424242;
  color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
}
