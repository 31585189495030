.containerhome {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: center;
    margin: 0 auto;
    margin: 8rem 0 0 20rem;
    max-width: 900px;
    min-height: 100%;
    min-height: 80vh;
    width: 100%;
    /* background-color: red; */
  }

  .titulo1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 24px;
    width: 900px;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }