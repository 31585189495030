.top {
  display: flex;
  justify-content: space-between;
  height: 2rem;
  margin-top: 10px;
}

.previsualizar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 200px;
  height: 24px;
  margin-left: 22px;
  top: 92px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.conteudo {
  display: flex;
  justify-content: space-between;
}

.boxprevisualizacao {
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
  width: 560px;
  height: 280px;
  left: 16.89px;
  top: 125px;
  border-radius: 8px;
  background-color: #424242;
}

.boxescolhas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.utilizar {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 37.9px;
  height: 37.9px;
  left: 660px;
  top: 245.9px;
  background: #009ef7;
  border-radius: 8.48952px;
  margin-bottom: 10px;
  cursor: pointer;
}
.cena {
  width: 77px;
  height: 24px;
  left: 602px;
  top: 254px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.fechar {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 37.9px;
  height: 37.9px;
  left: 659.9px;
  /* top: 327.9px; */
  background: #f83e3e;
  border-radius: 8.48952px;
  margin-top: 1rem;
  margin-bottom: 10px;
  cursor: pointer;
}

.finalizar {
  width: 89px;
  height: 24px;
  left: 596px;
  top: 336px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}

.boxtransmitindo {
  display: flex;
  justify-content: center;
  margin-right: 1.5rem;
  width: 560px;
  height: 280px;
  left: 16.89px;
  top: 125px;
  border-radius: 8px;
  background-color: #424242;
}

.iconetransmitindo {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
}
.transmitindo {
  width: 93px;
  height: 16px;
  left: 1172px;
  top: 98px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-right: 1.5rem;
  color: #28fdc3;
}

/* .boxstream {
  display: flex;
  justify-content: center;
  gap: .3rem;
  margin: 10px;
  margin-top: 15px;
}
.stream {
  width: 74.97px;
  height: 49.98px;
  top: 460.48px;
  background-color: #adaaaa;
  border-radius: 4.54371px;
  cursor: pointer;
} */

.participantes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.8rem;
  margin: 1rem 10px 5px 10px;
  /* margin-left: 22px;
  margin-top: 1rem; */
}

.boxum {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 195px;
  height: 133px;
  left: 292px;
  top: 198px;
  background: #424242;
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #ffffff;
  gap: 1rem;
  cursor: pointer;
}

h4 {
  width: 139px;
  height: 18px;
  left: 43px;
  top: 639px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.boxdois {
  display: flex;
  flex-direction: column;
  width: 195px;
  height: 133px;
  border-radius: 4px;
  color: #ffffff;
}

.bordaazul {
  display: flex;
  justify-content: space-between;
  background-color: #009ef7;
  width: 195px;
  height: 20px;
  left: 292px;
  border-radius: 2px;
}
.parti {
  width: 139px;
  height: 18px;
  left: 43px;
  top: 639px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 1rem;
}

.mic {
  cursor: pointer;
}

.trespontos {
  background-color: transparent;
  cursor: pointer;
  border: none;
  margin: 2px 0 0 125px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index:1;
}

.bordacinza {
  display: flex;
  justify-content: space-between;
  background-color: #424242;
  width: 195px;
  height: 20px;

  border-radius: 2px;
}
