.header {
  position: fixed;

  border-bottom: 1px solid rgba(252, 252, 252, 0.1);
  background-color: #1e222b;
  width: 100%;
  z-index: 1000;
  top: 0px;
}

nav {
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
}
.Logo_Header{
  padding: 5px;
}
.select_logout {
  display: flex;
}


/* .imgheader {
  margin: 5px;
  width: 10%;
  width: 150px;
  height: 50.62px;
  left: 3px;
  top: 0px;
  position: fixed;
} */
