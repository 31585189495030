.menu {
    display: flex;
    box-sizing: border-box;
    position: fixed;
    width: 220px;
    height: 100%;
    left: 0px;
    top: -20px;
    z-index: 800;
    border-right: 1px solid rgba(252, 252, 252, 0.1);
    background-color: #1e222b;
}

ul {
    border-top: 1px solid rgba(252, 252, 252, 0.1);
    width: 197px;
    height: 664px;
    left: 0px;
    top: 40px;
}

li a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 11px 10px;
    gap: 12px;
    margin: 10px;
    position: relative;
    cursor: pointer;
    width: 140px;
    height: 41px;
    left: 25px;
    top: 88px;
    border-radius: 6px;
    color: white;
    width: 165px;
    background-color: #1e222b;
}

.active a {
    background-color: #009ef7;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 11px 10px;
    gap: 12px;
    /* margin: 10px; */
    position: relative;
    cursor: pointer;
    width: 140px;
    height: 41px;
    left: 25px;
    /* top: 50px; */
    border-radius: 6px;
    color: white;
    width: 165px;
}

.icon {
    width: 25px;
    height: 25px;
    left: 1069.27px;
    top: 7px;
    border-radius: 8px 0px 0px 8.48952px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .menubutton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 10px;
  gap: 12px;
  margin: 10px;
  position: relative;
  cursor: pointer;
  width: 157px;
  height: 41px;
  left: 15px;
  top: 88px;
  border-radius: 6px;
  color: white;
  transition: all 0.3s;
  width: 165px;
  background-color: #1e222b;
} */
