.form {
  display: flex;
  margin-top: 1rem;
  margin-left: 1.5rem;
}

.h2_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 45px;
  left: 477px;
  top: 218px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: #1e222b;
  margin-bottom: 1rem;
}

.label_Modal {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e222b;
  margin: 2px;
}
.error_span{
  color: red;
  font-size: 9px;
  padding-left: 10px;
}
.txt_opcional {
  display: flex ;
  justify-content: center;
  text-align: center;
  font-size: 8px;
  padding: 1px 0 0 5px;
  color:red;
}

.label_ModalCheckbox {
  display: flex;
  align-items: center;
  width: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1e222b;
  /* margin: 2px; */
}

.input_ModalCheckbox {
  /* box-sizing: border-box; */
  width: 20px;
  height: 46px;
  /* background: #ffffff; */
  border: 1px solid #0095e8;
  border-radius: 6px;
  box-shadow: none;
  margin: 0;
  border-color: #0095e8;
}

.Checkbox_Box {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.input_Modal {
  box-sizing: border-box;
  width: 312px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #0095e8;
  border-radius: 6px;
  box-shadow: none;
}

.input_Modal:focus{
  /* border: 2px solid #0095e8; */

  border: 1px solid #0095e8;
  /* padding: 5px; */
  box-shadow: .5px 1px 1px .5px hsl(201, 100%, 45%);
}

input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.btn_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 45px;
  /* left: 486px; */
  background: #009ef7;
  border-radius: 6px;
  margin: 0px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: ease-in-out 0.5s ;
}

.btn_Modal:hover{
    transform: scale(1.05);  
}

.btn_Cancel_Modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  margin-left: 82px;
  width: 150px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1e222b;
  transition: ease-in-out 0.5s ;
  margin-top: 1rem;
  border: none;
  cursor: pointer;
}

.btn_Cancel_Modal:hover{
  
  transform: scale(1.1);  
}
