.rdt {
  position: relative;
}

/*caixa*/
.rdtPicker {
  display: none;
  position: absolute;
  min-width: 220px; 
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #0095e8;
}
.rdtOpen .rdtPicker {
  display: block;
 
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
  
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  background-color: transparent;

}

.rdtPicker table {
  /* width: 30%; */
  /* margin-left: 19px; */
  
  
}
/*formt da tabela*/
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 1px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
  
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #0095e8;
  color: #fff;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}

/*Tamanhos das fonts*/

.rdtDay{
  font-size: 17px;
  background-color: white;
  color: black;
}
.rdtDays{
  justify-content: center;
  margin-left: 19px;
}
.dow {
  font-size: 15px;
  background-color: white;
  color: black;
}
.rdtSwitch{
  width: 50px;
  font-size: 15px;
  background-color: white;
  color: black;
}

.rdtBtn{
  font-size: 10px;
  background-color: white;
  color: black;
}

.rdtCount{
  font-size: 30px;
  background-color: white;
  color: black;
}

.rdtPicker th.rdtSwitch {
  width: 100px;
  background-color: white;
  color: black;
}

/*setinha d trocar*/
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 15px;
  vertical-align: top;
  background-color: white;
  color: black;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
  background-color: white;
  color: black;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-of-type th {
  cursor: pointer;
}
.rdtPicker thead tr:first-of-type th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #0095e8;
}

.rdtPicker button {
  border: none;
  /* background: none; */
  cursor: pointer;
  color: white;
  background-color: #0095e8;
  border-radius: 5px;
  width: 90%;
  height: 100%;  
  transition: ease-in-out 0.5s ;
}
.rdtPicker button:hover {
  transform: scale(1.05);  
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
  
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

/* .rdtCounter {
  height: 500px;
} */

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #0095e8;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: .8em;
}
.rdtCounter{
  height: 100px;
}
.rdtTime{
  display: flex;
  justify-content: center;
}
.rdtCounters{
  display: flex;
}


.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
  
}

.rdtMilli input {
  width:100%;
  font-size: 1.2em;
  margin-top: 37px;
 outline: none;
 box-shadow: none;
 
}

.rdtTime td {
  cursor: default;
  
}

.input_Datetime{
  border: .5px solid #0095e8;
  width: 89.2%;
  height: 49px;
  margin-bottom: 1rem;
  border-radius: .5rem;
  cursor: pointer;
  /* box-shadow: none; */
  display: flex;
  justify-content: center;
  
  
}

.controls{
  display: flex;
  justify-content: center;

}

.datetime_close{
  background-color: red;
}