.select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 25px 0 0 ;
}
.iconuser {
  width: 37.9px;
  height: 37.5px;
  background: #009ef7;
  border-radius: 8px 0px 0px 8.48952px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectuser {
  width: 180px;
  height: 37.5px;
  border-radius: 0px 5px 5px 0px;
  background: #424242;
  margin: 1px 0 0 0;
  color: white;
}



.dropdown {
  position: relative;  
 
}

.more{
  background-color: #1e222b;
  border: none;
 margin: 18px 60px 0 0; 
  /* margin: 15px 0px 0 60px; */
  padding:0;
  cursor: pointer;
 
}

.menu_drop {
  position: absolute;
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  width: 150px;
  padding-bottom: 10px;
  border: none;

}

.menu_drop li {
  margin: 0; 
  background-color: #424242;
  color: white;
 
 
}

 .menu_drop li:hover { 
   background-color: #535050;   
   /* border: .1px solid #bec2c4;    */
}

.menu_drop li button {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 2px;
  margin: 0;
  font: inherit;
  cursor: pointer; 
  padding: 5px;

}

.menu-item1{
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 70px;
  
}
.menu-item2{
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 70px;
}



/* .dropdown {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.dropbtn {
  background-color: #1e222b;
  font-size: 16px;
  border: none;
  cursor: pointer;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 30px 0 0;
  position: relative;
}

.dropdown-content {
  position: absolute;
  background-color: #1e222b;
  z-index: 98;
  max-height: 0;
  min-width: 160px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  padding-right: 50px;
}

.dropdown-content li {
  color: white;
  background-color: #424242;
  padding: 12px;
  margin-right: 100px;
  text-decoration: none;
  display: block;
  margin-right: 100px;
}
.lista_um {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}
.lista_dois {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #656565;
}

.dropdown:hover .dropdown-content {
  max-height: 500px;
  min-width: 160px;
  transition: max-height 0.25s ease-in;
}

.dropdown:hover .dropbtn {
  background-color: #1e222b;
  transition: max-height 0.25s ease-in;
} */




/* #nav li a,
#nav li label {
  display: block;
  padding: 15px;
  cursor: pointer;
  max-width: 50%;
}

#nav li ul,
#nav li input {
  display: none;
  border: none;
}

#nav li input:checked + ul {
  display: block;
  color:white;
  cursor: pointer;
  min-width: 160px;
}
#nav li input:checked + ul:hover{
  background-color: #424242;
 
}

.lista_geral{
  width: 50px;
} */

/* #nav li {
  list-style: none;
  background: #656565;
  width: 50px;
  outline: none;
} */

/* #nav li a:hover,
#nav li label:hover {
  border-left: 4px solid #069;
  background: #f8f8f8;
} */

/* #nav li:hover ul li{background: #333;} */
/* #nav li:hover ul li a {color:ccc;} */
/* #nav li:hover ul li a:hover{background: #222; border-left:4px solid #900;} */
