


.Add_Participantes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

 
}
