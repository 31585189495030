.header {
  position: fixed;

  background-color: #1e222b;
  width: 100%;
  z-index: 1000;
  top: 0px;
}

.geral {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  /* border-bottom: 1px solid rgba(252, 252, 252, 0.1); */
}

.divesq {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 500px;
}

img {
  width: 142.22px;
  height: 48px;
  left: 68.17px;
  top: 10px;
}

.header_studioLogout{
  display: flex;
}

.studio_logout{
  margin-top: 7px;
}

/* button {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 155px;
  height: 38px;
  left: 1110px;
  margin-top: 20px;
  margin-right: 1.2rem;
  background: #009ef7;
  border-radius: 4.49px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #ffffff;
  border: none;
} */

.inputheader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235.6px;
  height: 38px;
  left: 225.4px;
  margin-top: 15.1px;
  background: #424242;
  border-radius: 8.48952px;
}

.inputheader::placeholder {
  display: flex;
  padding-left: 1.8rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.voltar {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 37.9px;
  height: 37.9px;
  left: 15.27px;
  /* margin-top: 13px; */
  background: #424242;
  border-radius: 8.48952px;
  cursor: pointer;
}

/* .iconetransmitir {
  display: flex;
  gap: 7px;
}
.compartilhar {
  display: flex;
  align-items: center;
} */
