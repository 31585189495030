label {
  color: #fff;
  /* position: absolute; */
  /* font-size: 9px; */
  /* margin-bottom: none;
    margin: 1px;
    margin-left: 2px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

input {
    /* position: absolute; */
    max-width: 100%;
    width: 345px;
    height: 46px;
    left: 800px;
    top: 283px;    
    background: #FFFFFF;
    border-radius: 6px;
    border: none;
    margin-bottom: 15px;   
}

input::placeholder {
    width: 285px;
    height: 24px;
    left: 813px;
    top: 294px;
    padding-left: 1.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;    
    color: #CCCACA;
}
