.transmissao3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 195px;
    height: 133px;
    left: 292px;
    top: 198px;
    padding: 1rem 2rem;
    border-radius: 4px;
    color: #ffffff;
    background-color: #1e222b;
    gap: 1rem;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.5s ;
}

.transmissao3:hover{
    transform: scale(1.2);
}