.containerhome {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: center;
    margin: 0 auto;
    margin: 8rem 0 0 20rem;
    max-width: 900px;
    min-height: 100%;
    min-height: 80vh;
    width: 100%;
  }

.form_Account{
  display: flex;
  align-items: flex-start;
  margin: 0;
}

.h2_Account{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 24px;
  width: 900px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 20px;
}

.btn_Editar{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 45px;
  /* left: 486px; */
  background: #009ef7;
  border-radius: 6px;
  margin-top: 10px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}