/* .home2 {
    background-image: url("../../assets/image/background_transparente.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  } */

/* .container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
  } */
/* gap: 100px; */
/* background-color: red; */

/* .logo2 {
    display: flex;
    justify-content:flex-end;  
    width: 200%;
     
  }*/

.boxlogin2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 3rem 2rem;
    background: #009ef7;
    border-radius: 24px;
}

.form2 {
    display: flex;
    margin-top: 1rem;
    margin-left: 1.5rem;
}

.h2_Modal2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 345px;
    height: 45px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 1000;
    font-size: 26px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 1rem;
}

.label_Modal2 {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 2px;
}

.input_Modal2 {
    box-sizing: border-box;
    width: 345px;
    height: 46p;
    background: #ffffff;
    border: 1px solid #0095e8;
    border-radius: 6px;
    box-shadow: none;
    margin-bottom: 1px;
}

.acessar2 {
    width: 345px;
    height: 45px;
    left: 800px;

    background: #00273d;
    color: #ffffff;
    border-radius: 6px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
