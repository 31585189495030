/* .home {
    background-image: url("../../assets/image/background_transparente.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  } */
  
  /* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    min-height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
  }  */
  /* background-color: red; */
  /* gap: 100px; */
  
  /* .logo {
    display: flex;
    justify-content:flex-end;  
    width: 200%;
    
  } */
  
  /* img {
    width: 548.15px;
  height: 185px;
  
  } */
  
  
   .boxlogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 430px;
    height: 450px;
    
    padding: 1rem 2rem;
  
    
    background: #009ef7;
    border-radius: 24px;
  }
  
  
  
  form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 1rem;
   
    
  }

  .erroremail_span{
    color: red;
    font-size: 12px;
  }
  
  
  h1 {
    display: flex;
    justify-content: center; 
    line-height: 48px;
    align-items: center;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
  }
  
  .acessar {
    width: 345px;
    height: 45px;
    left: 800px;
    top: 446px;
    background: #00273d;
    color: #ffffff;
    border-radius: 6px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
  
  .senha {
    width: 345px;
    height: 45px;
    left: 800px;
    top: 508px;
    background: #009EF7;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
    color:#FFFFFF;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
  }
  
  
  
  @media(max-width: 600px){
    img {
      display: none;
    }
    .boxlogin{
      position: absolute;
      align-content: center;
      width: 100%;
    }
  }