.transmissao2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 195px;
    height: 133px;
    left: 292px;
    top: 198px;
    background: #424242;
    padding: 1rem 2rem;
    border-radius: 4px;
    color: #ffffff;
    gap: 1rem;
    border: none;
    cursor: pointer;
    transition: ease-in-out 0.5s ;
  }

  .transmissao2:hover{
    transform: scale(1.04); 
  }
  
  h3 {
    font-size: 12px;
  }