.iconetransmitir {
  display: flex;
  gap: 7px;
}
.compartilhar {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.share_Transmission {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 155px;
  padding: 5px;
  height: 38px;
  left: 1110px;
  margin-top: 20px;
  margin-right: 1.2rem;
  background: #009ef7;
  border-radius: 4.49px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

