.list_title {
  color: #f2f2f2;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  font-family: "Poppins";

}

thead {
  width: 1016px;
  height: 32px;
  background: #424242;
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.list_empty{
  padding-bottom: 25px;
  background-color: #1e222b;
  font-family: "Poppins";
}

tbody:nth-child(even){
  background-color: rgba(255, 255, 255, 0.05);
}

.list_check{
  display: flex;
  justify-content: space-around;
}

.list_icon{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap:1rem;
}


