.home {
    background-image: url("../../assets/image/background_transparente.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  } 
  
   .container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2000px;
    min-height: 100%;
    min-height: 100vh;
    min-width: 100%;
    margin: 0 auto;
    /* margin-left: 50px; */
} 
/* gap: 100px; */
/* background-color: red; */
  
   .logo {
    min-width: auto;
    max-width: auto;
    width: 400px;
    height: 130px;    
    display: flex;
    justify-content:flex-end; 
  }
  .img_logo {
    min-width: auto;
    max-width: auto;
    width: 400px;
    height: 130px;
  }

   /* @media(max-width: 600px){
    img {
      display: none;
    }
    .boxlogin{
      position: absolute;
      align-content: center;
      width: 100%;
    }
  } */
